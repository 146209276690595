import React, { useState, useEffect, Suspense } from "react";
import "./style.css";
import { useLocation } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BlogsService from "../Services/blogs.service";
const LazyImage = React.lazy(() => import("./LazyImage"));

function Blog() {
  const location = useLocation();
  const { blogId } = location.state;
  const [blogs, setBlogs] = useState({});
  const [mainBlogs, setMainBlogs] = useState([]);
  const [blogDesc, setBlogDesc] = useState([]);
  const [blogID, setBlogID] = useState(blogId);

  useEffect(() => {
    BlogsService.fetchBlogsData()
      .then((response) => {
        setMainBlogs(response.data);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchBlogs();
  }, [blogID]); // Trigger fetchBlogs whenever blogID changes

  const fetchBlogs = () => {
    BlogsService.fetchBlogsData()
      .then((response) => {
        // console.log(response.data[11].description);
        const matchedBlog = response.data.find((blog) => blog.id === blogID);

        if (matchedBlog) {
          setBlogs(matchedBlog);
          // setBlogDesc(matchedBlog.description.split("\r\n\r\n"));
          const segments = matchedBlog.description.split("\r\n\r\n");
          const formattedSegments = segments.map((segment) =>
            segment.replace(/\r\n/g, "\n")
          );
          setBlogDesc(formattedSegments);
        } else {
          console.error("No blog found with the specified id");
        }
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1, // Changed mainBlogs to items
      },
      600: {
        items: 2, // Changed mainBlogs to items
      },
      1000: {
        items: 3, // Changed mainBlogs to items
      },
    },
  };

  return (
    <div>
      <div className="blogs-page-container">
        <div className="blog-img-container">
          <Suspense fallback={<div>Loading image...</div>}>
            <LazyImage
              src={`https://fidusindia.in/cit/assets/blog_images/${blogs.image}`}
              alt="blogImg"
            />
          </Suspense>
        </div>
        <div className="blog-decrip-box">
          <div className="blog-box">
            <h1>{blogs.title}</h1>

            {Array.isArray(blogDesc)
              ? blogDesc.map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split("\n").map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>
                        {line}
                        {lineIndex < paragraph.split("\n").length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </p>
                ))
              : null}
          </div>
        </div>
      </div>

      <div className="exploreBlogs">
        <div className="exploreBlogs-heading">Explore our other blogs!</div>
        <OwlCarousel className="owl-theme" {...options} loop>
          {Array.isArray(mainBlogs) && mainBlogs.length > 0
            ? mainBlogs.map((item) => (
                <div
                  key={item.id}
                  className="item"
                  style={{ height: "300px" }}
                  onClick={() => setBlogID(item.id)}
                >
                  <Suspense fallback={<div>Loading image...</div>}>
                    <LazyImage
                      src={`https://fidusindia.in/cit/assets/blog_images/${item.image}`}
                      alt={item.title}
                    />
                  </Suspense>
                  <div className="blog-op"></div>
                  <div className="blog-cowl-text">
                    <h6>{item.title}</h6>
                    <div>Read more</div>
                  </div>
                </div>
              ))
            : null}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default Blog;
